export const formatDate = (date) => {
  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: false, // use 24-hour clock format
  };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    new Date(date)
  );
  const [dateStr, timeStr] = formattedDate.split(", ");
  let formattedTime = timeStr.replace(" ", "");
  if (formattedTime.startsWith("24:")) {
    formattedTime = formattedTime.replace("24:", "00:");
  }
  return { date: dateStr.replace(/\//g, "/"), time: formattedTime };
};

export const formatDateAndTime = (dateString) => {
  const date = new Date(dateString);

  // Format the date to MM/DD/YY
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${String(
    date.getFullYear()
  ).slice(-2)}`;

  // Format the time to HH:mm (24-hour format)
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  // Return both date and time in the desired format
  return `${formattedDate} | ${formattedTime}`;
};
