import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  sendNotification,
  setLanguage,
} from "../../../store/actions/tickMapManagement";

import "../../common/Header.css";
import "../../../App.css";
import LanguageSelector from "./LanguageSelector";
import { useNavigate } from "react-router-dom";

export default function TickMapHeader(props) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.tickMap.selectedLanguage);
  const navigate = useNavigate();

  const handleLanguage = (event) => {
    const language = event.target.value;
    dispatch(setLanguage(language));
  };

  const handleSendNotification = () => {
    sendNotification();
  };

  return (
    <div
      style={{
        display: "flex",
        lineHeight: "0.01",
        marginBottom: "1em",
        height: "50px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="h5">{props.header}</Typography>
      </div>

      <div
        style={{
          display: "flex",
          marginLeft: "auto",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={handleSendNotification}
            style={{ marginRight: "1em", marginLeft: "1em", width: "200px" }}
          >
            Send Notification Only
          </Button>
          <LanguageSelector handleLanguage={handleLanguage} lang={language} />
          <Button
            variant="contained"
            size="small"
            type="submit"
            onClick={() => navigate(props.route)}
            style={{ marginRight: "1em", marginLeft: "1em" }}
          >
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
}
