import api from "./api";
import TokenService from "./token.service";

const login = async (email, password) => {
  try {
    const response = await api.post("/auth/login", {
      email,
      password,
    });

    if (response.data.user.isAdmin === false) {
      throw new Error(
        "You do not have admin privileges. Please contact the administrator for access."
      );
    }

    if (response.data.token) {
      TokenService.setUser(response.data);
    }

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const forgetpassword = async (email) => {
  const response = await api.post("/auth/forgot-password", {
    email,
  });

  return response;
};

const securityToken = async (token) => {
  const response = await api.post("/auth/verify-password-token", {
    token,
  });

  return response;
};

const resetPassword = async (email, token, password) => {
  const response = await api.post("/auth/reset-password", {
    email,
    password,
    token,
  });
  return response;
  // if (response.data.token) {
  //   TokenService.setUser(response.data);
  // }
  // return response.data;
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => JSON.parse(sessionStorage.getItem("user"));

const AuthService = {
  login,
  logout,
  getCurrentUser,
  forgetpassword,
  securityToken,
  resetPassword,
};

export default AuthService;
