import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVideoUrl } from "../../store/actions/currentArticle";
import { localizedUrlSelector } from "../../store/reducers/selector";
import TextFields from "./TextFields";

const VideoUrlField = () => {
  const dispatch = useDispatch();

  const url = useSelector(localizedUrlSelector);

  const HandleVideoUrl = (event) => {
    const text = event.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces

    dispatch(setVideoUrl(trimmedText));
  };

  return (
    <div>
      <TextFields
        placeholder={"eg. www.TickAcademy.com/video1911"}
        defaultValue={url || ""}
        name={"Copy and paste the video URL here:"}
        handleTextField={HandleVideoUrl}
        title={"Copy and paste the video URL here:"}
      ></TextFields>
    </div>
  );
};

export default VideoUrlField;
