import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
import BlobService from "../../../services/blob-service";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { FieldHeader } from "../Header";

const EditorField = ({
  name,
  value,
  onChange,
  error,
  helperText,
  label,
  setHtmlImage,
  ...props
}) => {
  const editorRef = useRef(null);

  const handleEditorChange = (content) => {
    // Call onChange to update the form state
    onChange({ target: { name, value: content } });
  };

  const handleImageUpload = async (blobInfo, success, failure) => {
    const file = blobInfo.blob();
    const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

    if (file.size > maxSize) {
      failure("File size exceeds the maximum allowed size of 20 MB");
      return;
    }

    try {
      new Compressor(file, {
        quality: 0.6,
        success: async (compressedFile) => {
          const filename = uuidv4();
          try {
            await BlobService.uploadImage(filename, compressedFile);
            const url = BlobService.getImageUri(filename);
            success(url);
            if (setHtmlImage) {
              setHtmlImage(filename);
            }
          } catch (uploadError) {
            failure(uploadError.message || "Image upload failed");
          }
        },
        error: (compressionError) => {
          console.error("Image compression error:", compressionError);
          failure("Image compression failed");
        },
      });
    } catch (error) {
      console.error("Image upload error:", error);
      failure("Image upload failed");
    }
  };

  return (
    <FormControl fullWidth error={Boolean(error)} margin="normal">
      <FieldHeader headerLabel={label} />
      <Editor
        textarea="content"
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value || ""}
        onEditorChange={handleEditorChange}
        init={{
          autosave_interval: "10s",
          height: 500,
          images_upload_max_size: 20971520, // 20MB in bytes
          menubar: "edit insert format table",
          file_picker_types: "image",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor autosave",
            "searchreplace visualblocks fullscreen",
            "insertdatetime media table paste help wordcount",
            "emoticons hr code",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic underline strikethrough | fontsizeselect | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist outdent indent | " +
            "removeformat | link image | emoticons hr | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

          autosave_ask_before_unload: false,
          images_upload_handler: handleImageUpload,
        }}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default EditorField;
