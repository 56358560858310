import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar, TimePicker } from "@mui/x-date-pickers";

import dayjs from "dayjs";

const CalenderDialog = React.forwardRef(
  ({ open, handleClose, handleOk, publishedDate }, ref) => {
    // Using refs to store date and time without using state
    // const selectedDateRef = React.useRef(null);
    let selectedTimeRef = React.useRef(null);

    // selectedTimeRef.current = dayjs(publishedDate);
    const [selectedDate, setSelectedDate] = React.useState();

    React.useEffect(() => {
      setSelectedDate(dayjs(publishedDate));
    }, [publishedDate]);

    // Expose the date and time refs to the parent component
    React.useImperativeHandle(ref, () => ({
      getSelectedDate: () => selectedDate,
      getSelectedTime: () => selectedTimeRef.current,
    }));

    const handleDateChange = (newDate) => {
      setSelectedDate(newDate);
    };

    const handleTimeChange = (newTime) => {
      selectedTimeRef.current = newTime;
    };

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pick Date & Time</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={selectedDate}
              onChange={handleDateChange}
              disablePast
            />
            <TimePicker
              className="timer"
              label="Select Time"
              value={selectedTimeRef.current || dayjs(publishedDate)}
              onChange={handleTimeChange}
              timeSteps={{ minutes: 30 }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions className="calendar-footer">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOk}>Schedule</Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default CalenderDialog;
