import {
  CREATE_PUBLISHER_REQUEST,
  CREATE_PUBLISHER_SUCCESS,
  GET_PUBLISHER_REQUEST,
  GET_PUBLISHER_SUCCESS,
  UPDATE_PUBLISHER_SUCCESS,
} from "../actions/type";

const initialState = {
  publisherData: [],
  publisherUpdated: false,
};
export const publisherReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PUBLISHER_REQUEST:
      return { ...state };
    case GET_PUBLISHER_SUCCESS:
      return {
        ...state,
        publisherData: [...action.payload],
        publisherUpdated: false,
      };
    case CREATE_PUBLISHER_REQUEST:
      return { ...state };
    case CREATE_PUBLISHER_SUCCESS:
      const { id, fullName } = action.payload;
      const newObj = {
        id,
        fullName,
      };

      return {
        ...state,
        publisherData: [...state.publisherData, newObj],
        loading: false,
        publisherUpdated: true,
      };
    case UPDATE_PUBLISHER_SUCCESS:
      return { ...state, publisherUpdated: true };
    default:
      return state;
  }
};
