import React from "react";
import { useSelector } from "react-redux";
import { localizedProductDosageSelector } from "../../../store/reducers/ProductSelector";
import { WithHeader } from "../../common/WithHeader";
import { setDosage } from "../../../store/actions/currentProduct";
import Content from "../../common/Content";

const Dosage = ({ setHtmlImage }) => {
  const productDosage = useSelector(localizedProductDosageSelector);
  return (
    <Content
      localizedContent={productDosage}
      setContent={setDosage}
      setHtmlImage={setHtmlImage}
    />
  );
};

export default WithHeader(Dosage, "Dosage");
