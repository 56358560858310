import {
  RETRIEVE_EDIT_RESOURCE_FAIL,
  RETRIEVE_EDIT_RESOURCE_SUCCESS,
  SELECTED_AUTHOR,
  TOGGLE_CATEGORY,
  SET_CONTENT,
  SET_COVER_IMAGE,
  SET_LANGUAGE,
  SET_READING_SCORE,
  SET_READING_TIME,
  SET_THUMBNAIL,
  SET_TITLE,
  SET_VEDIO_CONTENT,
  TOGGLE_RECOMMENDATION,
  SET_RESOURCE_TYPE,
  SET_FULL_NAME,
  SET_LOCAL_VALUE,
  SET_SCIENTIFIC_VALUE,
  SET_TICK_IMAGE,
  SET_ADDRESS,
  SET_DISTRICT,
  SET_PHONE,
  SET_INCHARGE,
  DELETE_TICK,
  SET_HTML_IMAGE,
  SET_VIDEO_URL,
  SELECTED_PUBLISHER,
} from "./type";
import api from "../../services/api";
import { trackPromise } from "react-promise-tracker";

export const setCategories = (selected) => async (dispatch) => {
  dispatch({
    type: TOGGLE_CATEGORY,
    payload: selected,
  });
};

export const setRecommendation = (selected) => async (dispatch) => {
  dispatch({
    type: TOGGLE_RECOMMENDATION,
    payload: selected,
  });
};

export const setAuthorId = (authorId) => async (dispatch) => {
  dispatch({
    type: SELECTED_AUTHOR,
    payload: authorId,
  });
};

export const setPublisherId = (publisherId) => async (dispatch) => {
  dispatch({
    type: SELECTED_PUBLISHER,
    payload: publisherId,
  });
};

export const addReadingTime = (readingTime) => async (dispatch) => {
  dispatch({
    type: SET_READING_TIME,
    payload: readingTime,
  });
};

export const setReadingScore = (score) => async (dispatch) => {
  dispatch({
    type: SET_READING_SCORE,
    payload: score,
  });
};

export const setCoverImage = (image) => async (dispatch) => {
  dispatch({
    type: SET_COVER_IMAGE,
    payload: image,
  });
};

export const setVedioContent = (file) => async (dispatch) => {
  dispatch({
    type: SET_VEDIO_CONTENT,
    payload: file,
  });
};

export const setVideoUrl = (url) => async (dispatch) => {
  dispatch({
    type: SET_VIDEO_URL,
    payload: url,
  });
};

export const setThumbnail = (image) => async (dispatch) => {
  dispatch({
    type: SET_THUMBNAIL,
    payload: image,
  });
};

export const setTitleValue = (title) => async (dispatch) => {
  dispatch({
    type: SET_TITLE,
    payload: title,
  });
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  });
};

export const setContent = (content) => async (dispatch) => {
  dispatch({
    type: SET_CONTENT,
    payload: content,
  });
};

export const setLearningResource = (resourceType) => async (dispatch) => {
  dispatch({
    type: SET_RESOURCE_TYPE,
    payload: resourceType,
  });
};

export const retrieveRescourcesbyId = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.get(`/learning-resource/${id}`));
    dispatch({
      type: RETRIEVE_EDIT_RESOURCE_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_EDIT_RESOURCE_FAIL, payload: err.message });
  }
};

export const clearCurrentRescource = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_EDIT_RESOURCE_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: RETRIEVE_EDIT_RESOURCE_FAIL, payload: err.message });
  }
};

export const setLocalValue = (localValue, index) => async (dispatch) => {
  dispatch({
    type: SET_LOCAL_VALUE,
    payload: localValue,
    index,
  });
};

export const setScientificValue =
  (scientificName, index) => async (dispatch) => {
    dispatch({
      type: SET_SCIENTIFIC_VALUE,
      payload: scientificName,
      index,
    });
  };

export const setTickImage = (filename) => async (dispatch) => {
  dispatch({
    type: SET_TICK_IMAGE,
    payload: filename,
  });
};

export const deleteTick = (index) => async (dispatch) => {
  dispatch({
    type: DELETE_TICK,
    payload: index,
  });
};

export const setHtmlImage = (image) => async (dispatch) => {
  dispatch({
    type: SET_HTML_IMAGE,
    payload: image,
  });
};
