import { localizedObject } from "../../component/common/GeneralHelper";
import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import {
  CREATE_TICK_MAP_REQUEST,
  CREATE_TICK_MAP_SUCCESS,
  CREATE_TICK_MAP_FAILURE,
  SET_TICKMAP_LANGUAGE,
  GET_TICK_MAP_REQUEST,
  GET_TICK_MAP_SUCCESS,
  GET_TICK_MAP_FAILURE,
  UPDATE_TM_FORM_DATA,
  SET_TM_SUCCESS,
  SET_COVER_IMAGE_TICK_MAP,
} from "./type";
import { TICK_MAP_URL } from "../../constants/apiUrls";

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({
    type: SET_TICKMAP_LANGUAGE,
    payload: lang,
  });
};

export const updateSuccess = (status) => async (dispatch) => {
  dispatch({
    type: SET_TM_SUCCESS,
    payload: status,
  });
};

export const setCoverImage = (data) => async (dispatch) => {
  dispatch({
    type: SET_COVER_IMAGE_TICK_MAP,
    payload: data,
  });
};

export const updateFormData = (updatedData) => async (dispatch) => {
  dispatch({
    type: UPDATE_TM_FORM_DATA,
    payload: updatedData,
  });
};

export const getTickMap = (params) => async (dispatch) => {
  dispatch({ type: GET_TICK_MAP_REQUEST });

  try {
    const response = await trackPromise(api.get(TICK_MAP_URL, { params }));

    if (response.data.data !== null) {
      dispatch({
        type: GET_TICK_MAP_SUCCESS,
        payload: response.data.data ?? null, // Safeguard if `data.data` is undefined
      });

      dispatch({
        type: SET_COVER_IMAGE_TICK_MAP,
        payload: response.data.data.coverImage,
      });
    }

    // resolve(response.data.data); // Resolves the promise with the fetched data
  } catch (error) {
    const errorMessage =
      error.response?.data.data || "An unexpected error occurred";

    dispatch({
      type: GET_TICK_MAP_FAILURE,
      payload: errorMessage,
    });

    // reject(errorMessage); // Rejects the promise with the error message
  }
};

export const createTickMap = (payload) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: CREATE_TICK_MAP_REQUEST });
    try {
      const response = await trackPromise(api.post(TICK_MAP_URL, payload));

      dispatch({
        type: CREATE_TICK_MAP_SUCCESS,
        payload: response.data ?? null, // Safeguard in case `data` or `data.data` is undefined
      });

      resolve(response.data); // Resolves the promise with successful data
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || "An unexpected error occurred";

      dispatch({
        type: CREATE_TICK_MAP_FAILURE,
        payload: errorMessage,
      });

      reject(errorMessage); // Rejects the promise with the error message
    }
  });
};

export const sendNotification = (params) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    // dispatch({ type: GET_TICK_MAP_REQUEST });

    try {
      const response = await trackPromise(
        api.get(TICK_MAP_SEND_NOTIFICATION, { params })
      );

      // dispatch({
      //   type: GET_TICK_MAP_SUCCESS,
      //   payload: response.data ?? null, // Safeguard if `data.data` is undefined
      // });

      resolve(response.data); // Resolves the promise with the fetched data
    } catch (error) {
      // const errorMessage =
      //   error.response?.data || "An unexpected error occurred";

      // dispatch({
      //   type: GET_TICK_MAP_FAILURE,
      //   payload: errorMessage,
      // });

      reject(errorMessage); // Rejects the promise with the error message
    }
  });
};
