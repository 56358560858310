/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { useLocation, useNavigate } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import CollectionsIcon from "@mui/icons-material/Collections";
import VideoIcon from "@mui/icons-material/Videocam";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import PestControlIcon from "@mui/icons-material/PestControl";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PolicyIcon from "@mui/icons-material/Policy";
import NearMeIcon from "@mui/icons-material/NearMe";
// import ContactsIcon from '@mui/icons-material/Contacts';
// internal import
import ArticleList from "../articles/list-article/ArticleList";
import VedioList from "../video/list-video/VedioList";
import TrainingList from "../training/training-list/TrainingList";
import GalleryList from "../gallery/list-gallery/GalleryList";
import KYTList from "../know-your-ticks/Listing/KYTList";
import EventListing from "../events/listing/EventListing";
import ScoreCard from "../scoreCard/ScoreCard";
import { useDispatch } from "react-redux";
import { setCurrentResourceType } from "../../store/actions/resourceType";
import ProductListing from "../product/Listing/ProductListing";
import MixpanelChart from "./MixpanelChart";
import TermAndCondition from "../termCondition";
import DataPolicy from "../dataPrivacy";
import NearByList from "../NearBy/NearByList/NearByList";
import TickMap from "../TickMap";
// import ContactUs from '../contact-us';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function SideList() {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState("article");
  const list = useMemo(
    () => [
      {
        title: "Dashboard",
        icon: <DashboardOutlinedIcon fontSize="small" />,
        link: "dashboard",
        component: <MixpanelChart />,
      },
      {
        title: "Articles",
        icon: <ArticleIcon fontSize="small" />,
        link: "article",
        component: <ArticleList />,
      },
      {
        title: "Videos",
        icon: <VideoIcon fontSize="small" />,
        link: "video",
        component: <VedioList />,
      },
      {
        title: "Gallery",
        icon: <CollectionsIcon fontSize="small" />,
        link: "gallery",
        component: <GalleryList />,
      },
      {
        title: "Slidecasts",
        icon: <ModelTrainingIcon fontSize="small" />,
        link: "training",
        component: <TrainingList />,
      },
      {
        title: "Know Your Ticks",
        icon: <PestControlIcon fontSize="small" />,
        link: "tick",
        component: <KYTList />,
      },
      {
        title: "Events",
        icon: <CalendarMonthOutlinedIcon fontSize="small" />,
        link: "events",
        component: <EventListing />,
      },
      {
        title: "Products",
        icon: <AddShoppingCartIcon fontSize="small" />,
        link: "products",
        component: <ProductListing />,
      },
      {
        title: "Score Card",
        icon: <CreditCardOutlinedIcon fontSize="small" />,
        link: "score",
        component: <ScoreCard />,
      },
      {
        title: "Terms & Conditions",
        icon: <NoteAddIcon fontSize="small" />,
        link: "terms-and-condition",
        component: <TermAndCondition />,
      },
      {
        title: "Privacy Policy",
        icon: <PolicyIcon fontSize="small" />,
        link: "data-policy",
        component: <DataPolicy />,
      },
      {
        title: "Near By",
        icon: <NearMeIcon fontSize="small" />,
        link: "NearBy",
        component: <NearByList />,
      },
      {
        title: "Tick Map Management",
        icon: <NearMeIcon fontSize="small" />,
        link: "tick-map-management",
        component: <TickMap />,
      },
      // {
      //   title: 'Contact Us',
      //   icon: <ContactsIcon fontSize="small" />,
      //   link: 'contact-us',
      //   component: <ContactUs />,
      // },
    ],
    []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const routesMapping = {
    article: "article",
    video: "video",
    gallery: "gallery",
    training: "training",
    tick: "tick",
    event: "events",
    product: "products",
    score: "score",
    dashboard: "dashboard",
    "terms-and-condition": "terms-and-condition",
    "data-policy": "data-policy",
    NearBy: "NearBy",
    "tick-map-management": "tick-map-management",
  };

  useEffect(() => {
    const matchedKey = Object.keys(routesMapping).find((key) => {
      return location.pathname.substring(1) === key;
    });
    // If a match is found, set the selected link
    if (matchedKey) {
      setSelectedLink(routesMapping[matchedKey]);
    }
    //  else if (location.pathname.includes('contact-us')) {
    //   setSelectedLink('contact-us');
    // }
    else {
      setSelectedLink("");
    }
    sessionStorage.setItem("selectedLink", selectedLink);
    dispatch(
      setCurrentResourceType(
        selectedLink.charAt(0).toUpperCase() +
          selectedLink.slice(1).toLowerCase()
      )
    );
  }, [dispatch, location.pathname, selectedLink, location]);

  const handleItemClick = (link) => {
    navigate(link); // Perform navigation to the clicked link
    setSelectedLink(link); // Update the selected link state
    dispatch(
      setCurrentResourceType(
        link.charAt(0).toUpperCase() + link.slice(1).toLowerCase()
      )
    );
    // sessionStorage.setItem('selectedLink', link);
  };
  return (
    <>
      <Drawer
        variant="permanent"
        open={true}
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
          },
        }}
      >
        <DrawerHeader>
          <IconButton>
            <div></div>
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {list &&
            list.map((item) => (
              <ListItem
                key={item.title}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    // justifyContent: 'initial',
                    px: 2.5,
                    backgroundColor:
                      selectedLink === item.link ? "#118197" : "transparent",
                    // '&:hover': {
                    //   backgroundColor:
                    //     selectedLink === item.link ? '#118197' : '#333333',
                    // },
                  }}
                  onClick={() => handleItemClick(item.link)} // Handle item click
                  selected={selectedLink === item.link}
                  style={
                    selectedLink === item.link
                      ? { backgroundColor: "#118197" }
                      : {}
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      color: "white",
                      mr: 3,
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    style={{ fontWeight: "bolder", opacity: 4 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Drawer>
    </>
  );
}

export default SideList;
