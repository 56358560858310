import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const SelectionLimitDialog = ({ data, count, open, onClose }) => {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          You can only choose {count} {data}
        </DialogTitle>
        <DialogContent>
          <p>Please uncheck one of the selected {data} to add a new one.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectionLimitDialog;
