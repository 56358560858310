import React from "react";
import Login from "../component/authentication/Login";
import ForgetPassword from "../component/authentication/ForgetPassword";
import ResetPassword from "../component/authentication/ResetPassword";
import SecuityCode from "../component/authentication/SecuityCode";
import ArticleList from "../component/articles/list-article/ArticleList";
import NewArticle from "../component/articles/create-article/NewArticle";
import VedioList from "../component/video/list-video/VedioList";
import GalleryList from "../component/gallery/list-gallery/GalleryList";
import TrainingList from "../component/training/training-list/TrainingList";
import Training from "../component/training/training/Training";
import Gallery from "../component/gallery/gallery/Gallery";
import Video from "../component/video/video/Video";
import KYTList from "../component/know-your-ticks/Listing/KYTList";
import Tick from "../component/know-your-ticks/Editing/Tick";
import EventListing from "../component/events/listing/EventListing";
import EventForm from "../component/events/EventForm";
import ProductListing from "../component/product/Listing/ProductListing";
import Product from "../component/product/AddProduct/Product";
import ScoreCard from "../component/scoreCard/ScoreCard";
import NearByList from "../component/NearBy/NearByList/NearByList";
import NewNearBy from "../component/NearBy/NearByList/AddNewNearBy/NewNearBy";
import HelpDeleteAccount from "../component/HelpPageForm/HelpingPage";
import DeleteConfirmationPage from "../component/delete-confirmation/index";

import {
  ARTICLE_URL,
  CHART_URL,
  NEARBY_URL,
  NEW_NEARBY_URL,
  DATAPOLICY_URL,
  EDIT_ARTICLE_URL,
  EDIT_EVENT_URL,
  EDIT_GALLERY_URL,
  EDIT_PRODUCT_URL,
  EDIT_TICK_URL,
  EDIT_TRAINING_URL,
  EDIT_VIDEO_URL,
  EVENT_URL,
  FORGET_PASSWORD_URL,
  GALLERY_URL,
  LOGIN_URL,
  NEW_ARTICLE_URL,
  NEW_EVENT_URL,
  EDIT_NEARBY_URL,
  NEW_GALLERY_URL,
  NEW_PRODUCT_URL,
  NEW_TRAINING_URL,
  NEW_VIDEO_URL,
  PRODUCT_URL,
  RESET_PASSWORD_URL,
  SCORE_URL,
  SECURITY_CODE_URL,
  TERM_AND_CONDITION_URL,
  TICK_URL,
  TRAINING_URL,
  VIDEO_URL,
  CONTACTUS_URL,
  NEARBYHELP_BULKIMPORT_URL,
  HELP_URL,
  CONFIRM_DELETION,
  TICK_MAP_URL,
} from "../constants/apiUrls";
import MixpanelChart from "../component/dashboard/MixpanelChart";
import TermAndCondition from "../component/termCondition";
import DataPolicy from "../component/dataPrivacy";
import ContactUs from "../component/contact-us/index";
import BulkImport from "../component/NearBy/NearByList/BulkImport";
import { ContentPasteOffRounded } from "@mui/icons-material";
import TickMap from "../component/TickMap";
import TickMapManagement from "../pages/TickMapManagement";

const AppRoutes = [
  {
    name: "Login",
    path: LOGIN_URL,
    component: <Login />,
    requireAuthentication: false,
  },
  {
    name: "Forget Password",
    path: FORGET_PASSWORD_URL,
    component: <ForgetPassword />,
    requireAuthentication: false,
  },
  {
    name: "ResetPassword",
    path: RESET_PASSWORD_URL,
    component: <ResetPassword />,
    requireAuthentication: false,
  },
  {
    name: "SecuityCode",
    path: SECURITY_CODE_URL,
    component: <SecuityCode />,
    requireAuthentication: false,
  },
  {
    name: "Article",
    path: ARTICLE_URL,
    component: <ArticleList />,
    requireAuthentication: true,
  },
  {
    name: "New Article",
    path: NEW_ARTICLE_URL,
    component: <NewArticle />,
    requireAuthentication: true,
  },
  {
    name: "Edit Article",
    path: EDIT_ARTICLE_URL,
    component: <NewArticle />,
    requireAuthentication: true,
  },
  {
    name: "Video",
    path: VIDEO_URL,
    component: <VedioList />,
    requireAuthentication: true,
  },
  {
    name: "Edit Video",
    path: EDIT_VIDEO_URL,
    component: <Video />,
    requireAuthentication: true,
  },
  {
    name: "New Video",
    path: NEW_VIDEO_URL,
    component: <Video />,
    requireAuthentication: true,
  },
  {
    name: "Training",
    path: TRAINING_URL,
    component: <TrainingList />,
    requireAuthentication: true,
  },
  {
    name: "New Training",
    path: NEW_TRAINING_URL,
    component: <Training />,
    requireAuthentication: true,
  },
  {
    name: "Edit Training",
    path: EDIT_TRAINING_URL,
    component: <Training />,
    requireAuthentication: true,
  },
  {
    name: "Gallery",
    path: GALLERY_URL,
    component: <GalleryList />,
    requireAuthentication: true,
  },
  {
    name: "New Gallery",
    path: NEW_GALLERY_URL,
    component: <Gallery />,
    requireAuthentication: true,
  },
  {
    name: "Edit Gallery",
    path: EDIT_GALLERY_URL,
    component: <Gallery />,
    requireAuthentication: true,
  },
  {
    name: "Know Your Ticks",
    path: TICK_URL,
    component: <KYTList />,
    requireAuthentication: true,
  },
  {
    name: "Edit Know Your Tick",
    path: EDIT_TICK_URL,
    component: <Tick />,
    requireAuthentication: true,
  },
  {
    name: "Events",
    path: EVENT_URL,
    component: <EventListing />,
    requireAuthentication: true,
  },
  {
    name: "New Event",
    path: NEW_EVENT_URL,
    component: <EventForm />,
    requireAuthentication: true,
  },
  {
    name: "Edit event",
    path: EDIT_EVENT_URL,
    component: <EventForm />,
    requireAuthentication: true,
  },
  {
    name: "Product",
    path: PRODUCT_URL,
    component: <ProductListing />,
    requireAuthentication: true,
  },
  {
    name: "Product",
    path: NEW_PRODUCT_URL,
    component: <Product />,
    requireAuthentication: true,
  },
  {
    name: "Product",
    path: EDIT_PRODUCT_URL,
    component: <Product />,
    requireAuthentication: true,
  },
  {
    name: "Score Card",
    path: SCORE_URL,
    component: <ScoreCard />,
    requireAuthentication: true,
  },
  {
    name: "Chart",
    path: CHART_URL,
    component: <MixpanelChart />,
    requireAuthentication: true,
  },
  {
    name: "Terms and Condition",
    path: TERM_AND_CONDITION_URL,
    component: <TermAndCondition />,
    requireAuthentication: true,
  },
  {
    name: "Data Policy",
    path: DATAPOLICY_URL,
    component: <DataPolicy />,
    requireAuthentication: true,
  },
  {
    name: "Contact Us",
    path: CONTACTUS_URL,
    component: <ContactUs />,
    requireAuthentication: true,
  },
  {
    name: "Near By",
    path: NEARBY_URL,
    component: <NearByList />,
    requireAuthentication: true,
  },
  {
    name: "New Nearby",
    path: NEW_NEARBY_URL,
    component: <NewNearBy />,
    requireAuthentication: true,
  },
  {
    name: "Edit Nearby",
    path: EDIT_NEARBY_URL,
    component: <NewNearBy />,
    requireAuthentication: true,
  },
  {
    name: "NearbyHelp Bulk-Import",
    path: NEARBYHELP_BULKIMPORT_URL,
    component: <BulkImport />,
    requireAuthentication: true,
  },
  {
    name: "Help",
    path: HELP_URL,
    component: <HelpDeleteAccount />,
    requireAuthentication: false,
  },
  {
    name: "Delete Confirmation",
    path: CONFIRM_DELETION,
    component: <DeleteConfirmationPage />,
    requireAuthentication: false,
  },
  {
    name: "Tick Map Management",
    path: TICK_MAP_URL,
    component: <TickMapManagement />,
    requireAuthentication: true,
  },
];

export default AppRoutes;
