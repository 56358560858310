/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { formatDate } from "../../common/DateFormatter";
import {
  deleteProduct,
  getProducts,
  toggleProductActivation,
} from "../../../store/actions/product";
import { CONTENT_STATES } from "../../../constants/GeneralConstant";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const ProductTable = ({ data }) => {
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: "title", headerName: "Title", width: 250 },
    { field: "status", headerName: "Status", flex: 0.6 },
    {
      field: "updatedat",
      headerName: "Last Updated",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );
        const HandleActivation = (params) => {
          const res = products.find((obj) => {
            return obj.id === params.id;
          });
          dispatch(toggleProductActivation(params.id, res))
            .then(() => {
              if (res.status === CONTENT_STATES.PUBLISHED) {
                toast.success("Successfully deactivated!");
              } else {
                toast.success("Successfully activated!");
              }
            })
            .catch(() => {
              toast.error("Failed to activate/deactivate resource!");
            });
        };

        const product = products.find((obj) => obj.id === params.id);

        let activation;
        if (product && product.status === CONTENT_STATES.PUBLISHED) {
          activation = (
            <Tooltip title="deactivate">
              <VisibilityOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }
        const [open, setOpen] = useState(false);

        const handleDelete = (params) => {
          dispatch(deleteProduct(params.id))
            .then(() => {
              // Refresh the article list
              dispatch(getProducts()).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete resource!");
            });
        };

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/product/${params.id}/edit`)}
              />
            </Tooltip>
            {activation}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                className="action-icon"
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() => setOpen(true)}
              />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
  ];
  const rows = products
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by updatedAt in descending order
    .map((item) => {
      const title = item.title.EN || item.title.LU || item.title.RY;

      return {
        id: item.id,
        title: title.substring(0, 25) + (title.length > 25 ? "..." : ""),
        status: item.status,
        updatedat: item.updatedAt
          ? `${formatDate(item.updatedAt).date} | ${
              formatDate(item.updatedAt).time
            } by ${
              item.updatedBy && item.updatedBy.fullname
                ? item.updatedBy.fullname
                : "Admin"
            }`
          : `${formatDate(item.createdAt).date} | ${
              formatDate(item.createdAt).time
            } by ${
              item.updatedBy && item.updatedBy.fullname
                ? item.updatedBy.fullname
                : "Admin"
            }`,
      };
    });

  const rows2 = data
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // Sort by updatedAt in descending order
    .map((item) => {
      const title = item.title.EN || item.title.LU || item.title.RY;

      return {
        id: item.id,
        title: title.substring(0, 25) + (title.length > 25 ? "..." : ""),
        status: item.status,
        updatedat: item.updatedAt
          ? `${formatDate(item.updatedAt).date} | ${
              formatDate(item.updatedAt).time
            } by ${
              item.updatedBy && item.updatedBy.fullname
                ? item.updatedBy.fullname
                : "Admin"
            }`
          : `${formatDate(item.createdAt).date} | ${
              formatDate(item.createdAt).time
            } by ${
              item.updatedBy && item.updatedBy.fullname
                ? item.updatedBy.fullname
                : "Admin"
            }`,
      };
    });

  function CustomNoRowsOverlay() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <div>
          <p>No results found!</p>
          <p>Try changing the search criteria and try again…</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ height: data.length < 5 ? 600 : 1200, width: "100%" }}>
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProductTable;
