import React from "react";
import TickMapHeader from "./TickMapComponents/header";

export default function TickMap() {
  return (
    <>
      <TickMapHeader></TickMapHeader>
    </>
  );
}
