import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import {
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_EVENT_CATEGORY_FAILURE,
  CREATE_EVENT_CATEGORY_SUCCESS,
  DELETE_ACCOUNT_REQUEST_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_EVENT_CATEGORY_FAILURE,
  DELETE_EVENT_CATEGORY_REQUEST,
  DELETE_EVENT_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_EVENT_CATEGORIES_FAILURE,
  GET_EVENT_CATEGORIES_SUCCESS,
} from "./type";
import { localizedObject } from "../../component/common/GeneralHelper";

export const createCategory = (title, lang) => async (dispatch) => {
  dispatch({ type: CREATE_CATEGORY_REQUEST });

  const titleData = localizedObject(lang, title);
  // Conditionally set the title properties based on the lang value

  try {
    const response = await trackPromise(
      api.post("/category", {
        title: titleData,
      })
    );
    dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: CREATE_CATEGORY_FAILURE, payload: error.message });
  }
};

export const deleteCategory = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CATEGORY_REQUEST });

  // const titleData = localizedObject(lang, title);
  // Conditionally set the title properties based on the lang value

  try {
    const response = await trackPromise(api.delete(`/category/${id}`));
    dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: DELETE_CATEGORY_FAILURE, payload: error.message });
  }
};

export const getCategories = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORIES_REQUEST });

  try {
    const response = await api.get("/category");
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_CATEGORIES_FAILURE, payload: error.message });
  }
};

export const getEventCategories = () => async (dispatch) => {
  dispatch({ type: GET_CATEGORIES_REQUEST });

  try {
    const response = await api.get("/event-category");
    dispatch({
      type: GET_EVENT_CATEGORIES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: GET_EVENT_CATEGORIES_FAILURE, payload: error.message });
  }
};

export const createEventCategory = (title, lang) => async (dispatch) => {
  dispatch({ type: CREATE_CATEGORY_REQUEST });

  const titleData = localizedObject(lang, title);
  // Conditionally set the title properties based on the lang value

  try {
    const response = await api.post("/event-category", {
      title: titleData,
    });
    dispatch({
      type: CREATE_EVENT_CATEGORY_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: CREATE_EVENT_CATEGORY_FAILURE, payload: error.message });
  }
};

export const deleteEventCategory = (id) => async (dispatch) => {
  dispatch({ type: DELETE_EVENT_CATEGORY_REQUEST });

  // const titleData = localizedObject(lang, title);
  // Conditionally set the title properties based on the lang value

  try {
    const response = await trackPromise(api.delete(`/event-category/${id}`));
    dispatch({
      type: DELETE_ACCOUNT_REQUEST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({ type: DELETE_EVENT_CATEGORY_FAILURE, payload: error.message });
  }
};
