import { trackPromise } from "react-promise-tracker";
import { localizedObject } from "../../component/common/GeneralHelper";
import {
  CREATE_PUBLISHER_REQUEST,
  CREATE_PUBLISHER_SUCCESS,
  GET_PUBLISHER_FAILURE,
  GET_PUBLISHER_REQUEST,
  GET_PUBLISHER_SUCCESS,
  UPDATE_PUBLISHER_FAILURE,
  UPDATE_PUBLISHER_REQUEST,
  UPDATE_PUBLISHER_SUCCESS,
} from "./type";
import api from "../../services/api";

export const createPublisher = (fullname, lang) => {
  const fullNameData = localizedObject(lang, fullname);

  return async (dispatch) => {
    dispatch({ type: CREATE_PUBLISHER_REQUEST });
    try {
      const response = await trackPromise(
        api.post("/publisher", {
          fullname: fullNameData,
        })
      );
      dispatch({ type: CREATE_PUBLISHER_SUCCESS, payload: response.data.data });
    } catch (error) {
      console.log("error:", error);
      dispatch({ type: CREATE_PUBLISHER_SUCCESS, payload: error.message });
    }
  };
};

export const updatePublisher = (id, profilePic) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_PUBLISHER_REQUEST });
    try {
      const response = await trackPromise(
        api.patch(`/publisher/${id}`, {
          profilePic,
        })
      );

      dispatch({ type: UPDATE_PUBLISHER_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: UPDATE_PUBLISHER_FAILURE, payload: error.message });
    }
  };
};

export const getPublishers = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PUBLISHER_REQUEST });
    try {
      const response = await api.get("/publisher");
      dispatch({ type: GET_PUBLISHER_SUCCESS, payload: response.data.data });
    } catch (error) {
      dispatch({ type: GET_PUBLISHER_FAILURE, payload: error.message });
    }
  };
};
