import { act } from "react-dom/test-utils";
import {
  CREATE_TICK_MAP_FAILURE,
  CREATE_TICK_MAP_REQUEST,
  CREATE_TICK_MAP_SUCCESS,
  GET_TICK_MAP_FAILURE,
  GET_TICK_MAP_REQUEST,
  GET_TICK_MAP_SUCCESS,
  SET_COVER_IMAGE_TICK_MAP,
  SET_TICKMAP_LANGUAGE,
  SET_TM_SUCCESS,
  UPDATE_TM_FORM_DATA,
} from "../actions/type";

const initialState = {
  formData: {
    coverImage: "",
    content: { EN: "", LU: "", RY: "" },
    link: "",
    notificationContent: { EN: "", LU: "", RY: "" },
  },
  selectedLanguage: "EN",
  status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  success: false,
};

export const tickMapManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COVER_IMAGE_TICK_MAP:
      return {
        ...state,
        coverImage: action.payload,
      };

    case GET_TICK_MAP_REQUEST:
      return { ...state, status: "loading", success: false };

    case GET_TICK_MAP_SUCCESS:
      return {
        ...state,
        status: "succeeded",
        success: false,
        formData: action.payload,
      };
    case GET_TICK_MAP_FAILURE:
      return {
        ...state,
        status: "failed",
        success: false,
        error: action.payload,
      };
    case SET_TICKMAP_LANGUAGE:
      return { ...state, success: false, selectedLanguage: action.payload };
    case UPDATE_TM_FORM_DATA:
      return { ...state, success: false, formData: action.payload };
    case CREATE_TICK_MAP_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case CREATE_TICK_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        success: true,
      };
    case CREATE_TICK_MAP_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case SET_TICKMAP_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        success: false,
      };

    case SET_TM_SUCCESS:
      return {
        ...state,
        success: false,
      };
    case SET_COVER_IMAGE_TICK_MAP:
      return state;
    default:
      return state;
  }
};
