export const LOGIN_URL = "/login";
export const RESET_PASSWORD_URL = "/set-password";
export const FORGET_PASSWORD_URL = "/forget";
export const SECURITY_CODE_URL = "/code";

export const ARTICLE_URL = "/article";
export const NEW_ARTICLE_URL = "/new-article";
export const EDIT_ARTICLE_URL = "article/:id/edit";

export const VIDEO_URL = "/video";
export const NEW_VIDEO_URL = "/new-video";
export const EDIT_VIDEO_URL = "/video/:id/edit";

export const TRAINING_URL = "/training";
export const NEW_TRAINING_URL = "/new-training";
export const EDIT_TRAINING_URL = "training/:id/edit";

export const GALLERY_URL = "/gallery";
export const NEW_GALLERY_URL = "/new-gallery";
export const EDIT_GALLERY_URL = "/gallery/:id/edit";

export const TICK_URL = "/tick";
export const EDIT_TICK_URL = "/tick/:id/edit";

export const EVENT_URL = "/events";
export const NEW_EVENT_URL = "/new-event";
export const EDIT_EVENT_URL = "event/:id/edit";

export const PRODUCT_URL = "/products";
export const NEW_PRODUCT_URL = "/new-product";
export const EDIT_PRODUCT_URL = "product/:id/edit";

export const SCORE_URL = "/score";
export const CHART_URL = "/dashboard";
export const TERM_AND_CONDITION_URL = "/terms-and-condition";
export const DATAPOLICY_URL = "/data-policy";
export const CONTACTUS_URL = "/contact-us";

export const NEARBY_URL = "/nearby";
export const NEW_NEARBY_URL = "/new-nearbylist";
export const EDIT_NEARBY_URL = "nearby/:id/edit";
// export const NEARBY_URL = '/NearBy';
// export const NEW_NEARBY_URL = '/new-nearby';
export const NEARBYHELP_BULKIMPORT_URL = "/nearbyHelp/bulkImport";
export const HELP_URL = "/help/delete-account";
export const CONFIRM_DELETION = "/confirm-deletion";

export const SPECIES = "/species";

export const GET_EVENT_CATEGORY = "/event-category";
export const POST_EVENT_CATEGORY = "/event-category";
export const EVENT_CATEGORY_BY_ID = "/event-category/:id";

export const GET_SCORECARD_LIST = "/scorecard/list";
export const SCORECARD = "/scorecard";

export const TICK_MAP_URL = "/tick-map-management";
export const TICK_MAP_SEND_NOTIFICATION =
  "/tick-map-management/send-notification";
