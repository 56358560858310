import { CardHeader, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRecommendation } from "../../store/actions/currentArticle";
import SelectionLimitDialog from "./SelectionLimitDialog";
import { WithHeader } from "./WithHeader";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";
import { Button } from "@mui/material";
import { RECOMMENDATION_FILTERS } from "../../constants/GeneralConstant";
import filter from "../../store/reducers/filter";
import { retrieveLearningResource } from "../../store/actions/learningResource";

const Recommendation = ({
  resource,
  lang,
  showRecommendationFilter = true,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([resource]);
  const selectedRecommendations = useSelector(
    (state) => state.article.recommendationsIds
  );
  let selectedRecommendationIds = selectedRecommendations.map((r) => r.id);

  const { learningResource } = useSelector((state) => state.learningResource);

  // useEffect to update recommendationFilters only when dependencies change
  useEffect(() => {
    let filteredResources = learningResource.filter(
      (article) =>
        selectedFilters.includes(article.learningResourceType) &&
        article.id !== id
    );

    if (selectedFilters.length === 0) {
      filteredResources = learningResource.filter(
        (article) => article.id !== id
      );
    }
    setFilteredRecommendations(filteredResources);
  }, [learningResource, selectedFilters, id]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleToggle = (id, recommendationTitle) => () => {
    const currentIndex = selected.indexOf(recommendationTitle);
    const isSelected = selectedRecommendationIds.includes(id);

    if (selectedRecommendationIds.length === 5 && !isSelected) {
      setDialogOpen(true);
      return;
    }
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(
        learningResource.find(
          (item) => item?.title?.[lang]
          // item?.title?.[LANGUAGE_KEY.LUGANDA] ||
          // item?.title?.[LANGUAGE_KEY.RUNYAKITARA] === recommendationTitle
        ).title
      );
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
    dispatch(setRecommendation(id));
  };

  const getRecommendationRow = (item) => {
    const title = item?.title?.[lang];
    if (!title) return;

    return (
      <>
        <Stack
          key={item.id}
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          marginLeft={2}
          sx={{ height: "4em" }}
        >
          <FormControlLabel
            value={item.id}
            control={
              <Checkbox
                checked={selectedRecommendationIds.includes(item.id)}
                onChange={handleToggle(item.id, title)}
              />
            }
          />
          <CardHeader key={item.id} title={title} sx={{ ml: -3 }} />
        </Stack>
      </>
    );
  };

  return (
    <>
      {showRecommendationFilter && (
        <div className="tabs-group">
          {RECOMMENDATION_FILTERS.map((item) => {
            if (item.type !== "Gallery") {
              const isSelected = selectedFilters.includes(item.type);

              return (
                <Button
                  className={
                    isSelected ? "Show-Selected-class" : "show-unselected-class"
                  }
                  onClick={() => {
                    setSelectedFilters(
                      (prevState) =>
                        isSelected
                          ? [
                              ...prevState.filter(
                                (filter) => filter !== item.type
                              ),
                            ] // Remove if already selected
                          : [...prevState, item.type] // Add if not selected
                    );
                  }}
                  key={item.type}
                >
                  {item.name}
                </Button>
              );
            }
          })}
        </div>
      )}
      <div style={{ marginTop: 10, overflowY: "auto", height: 300 }}>
        {filteredRecommendations?.length > 0 &&
          filteredRecommendations.map((item) => getRecommendationRow(item))}
      </div>
      <SelectionLimitDialog
        data="Recommendation"
        count="5"
        open={dialogOpen}
        onClose={handleCloseDialog}
      ></SelectionLimitDialog>
    </>
  );
};

export default WithHeader(Recommendation, "Recommendations");
