import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

export default function LanguageSelector({ handleLanguage, lang = null }) {
  return (
    <FormControl size="small" className="selector" sx={{ minWidth: "10rem" }}>
      <Select
        onChange={handleLanguage}
        value={lang}
        displayEmpty
        name="language"
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value={"EN"}>English</MenuItem>
        <MenuItem value={"LU"}>Luganda</MenuItem>
        <MenuItem value={"RY"}>Runyakitara</MenuItem>
      </Select>
    </FormControl>
  );
}
