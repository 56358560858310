import {
  CREATE_CATEGORY_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_EVENT_CATEGORY_FAILURE,
  CREATE_EVENT_CATEGORY_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_EVENT_CATEGORIES_FAILURE,
  GET_EVENT_CATEGORIES_SUCCESS,
} from "../actions/type";

const initialState = {
  categories: [],
  eventCategories: [],
  loading: false,
  error: null,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CATEGORY_REQUEST:
    case GET_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case CREATE_CATEGORY_SUCCESS:
      const { catId, catTitle } = action.payload;
      const newCategoryObj = {
        catId,
        catTitle,
      };
      return {
        ...state,
        categories: [...state.categories, newCategoryObj],
        loading: false,
      };
    case GET_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload, loading: false };

    case CREATE_CATEGORY_FAILURE:
    case GET_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case GET_EVENT_CATEGORIES_SUCCESS:
      return { ...state, eventCategories: action.payload, loading: false };
    case GET_EVENT_CATEGORIES_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case CREATE_EVENT_CATEGORY_SUCCESS:
      const { id, title } = action.payload;
      const newCategory = {
        id,
        title,
      };
      return {
        ...state,
        eventCategories: [...state.eventCategories, newCategory],
        loading: false,
      };
    case CREATE_EVENT_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
