import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import { categoryReducer } from "./category";
import { authorReducer } from "./author";
import { learningReducer } from "./currentArticle";
import learningResource from "./learningResource";
import events from "./event";
import { currentEventeReducer } from "./currentEvent";
import { organizorReducer } from "./organizor";
import { currentProductReducer } from "./currentProduct";
import { nearByReducer } from "./nearByReducer";
import productReducer from "./product";
import { speciesReducer } from "./species";
import admin from "./admin";
import resourceType from "./resourceType";
import filter from "./filter";
import score from "./scoreCard";
import { content } from "./policy";
import { FormReducer } from "./FormReducer";
import { DeleteAccountReducer } from "./deleteAccountMessageReducer";
import { publisherReducer } from "./publisher";
import { tickMapManagementReducer } from "./tickMapManagementReducer";

export default combineReducers({
  auth,
  message,
  categories: categoryReducer,
  article: learningReducer,
  authorData: authorReducer,
  publisherData: publisherReducer,
  organizorData: organizorReducer,
  learningResource,
  events,
  currentEvent: currentEventeReducer,
  currentProduct: currentProductReducer,
  products: productReducer,
  species: speciesReducer,
  nearBy: nearByReducer,
  formData: FormReducer,
  deleteAccount: DeleteAccountReducer,
  admin,
  filter,
  resourceType,
  score,
  content,
  tickMap: tickMapManagementReducer,
});
