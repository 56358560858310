import * as Yup from "yup";

const TickMapManagementSchema = Yup.object().shape({
  content: Yup.string()
    .required("Field is required.")
    .max(25, "Must be less than 25 characters"),
  link: Yup.string()
    .required("Field is required.")
    .max(25, "Must be less than 25 characters"),
});

export default TickMapManagementSchema;
