import {
  CREATE_AUTHOR_REQUEST,
  CREATE_AUTHOR_SUCCESS,
  GET_AUTHOR_REQUEST,
  GET_AUTHOR_SUCCESS,
  UPDATE_AUTHOR_SUCCESS,
} from "../actions/type";

const initialState = {
  authorData: [],
  authorUpdated: false,
};
export const authorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTHOR_REQUEST:
      return { ...state };
    case GET_AUTHOR_SUCCESS:
      return {
        ...state,
        authorData: [...action.payload],
        authorUpdated: false,
      };
    case CREATE_AUTHOR_REQUEST:
      return { ...state };
    case CREATE_AUTHOR_SUCCESS:
      const { id, fullName } = action.payload;
      const newObj = {
        id,
        fullName,
      };
      return {
        ...state,
        authorData: [...state.authorData, newObj],
        loading: false,
        authorUpdated: true,
      };
    case UPDATE_AUTHOR_SUCCESS:
      return { ...state, authorUpdated: true };
    default:
      return state;
  }
};
