import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import usePrompt from "../../hook/usePrompt";
import {
  localizedCoverImageSelector,
  localizedProductTitleSelector,
} from "../../../store/reducers/ProductSelector";
import ProductPreview from "../preview/ProductPreview";
import { toast } from "react-toastify";
import {
  createProduct,
  editProduct,
  getProducts,
} from "../../../store/actions/product";
import { validateProduct } from "../../validations/validateProduct";
import { CONTENT_STATES } from "../../../constants/GeneralConstant";
// import SplitButton from "../../common/SplitButtons";
// import CalenderDialog from "../../common/CalenderDialog";
// import { IconButton, Tooltip } from "@mui/material";
// import PreviewIcon from "@mui/icons-material/Preview";
// import DraftsIcon from "@mui/icons-material/Drafts";
import Preview from "../preview/ProductPreview";
const ProductButton = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const product = useSelector((state) => state.currentProduct);
  const productTitle = useSelector(localizedProductTitleSelector);
  const productCoverImage = useSelector(localizedCoverImageSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [openCalender, setOpenCalender] = useState(null);
  const dateTimeRef = React.useRef(null);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };
  const handleSaveResource = async (status) => {
    const validationError = validateProduct(product, productTitle);
    if (validationError) {
      toast.error(validationError);
      return;
    }
    product.status = status;
    // if (product.status === CONTENT_STATES.SCHEDULED) {
    //   product.publishedAt = combinedDateTime();
    // }

    //     // function assignENtoLU(obj) {
    //       for (const key in product) {
    //         if (typeof product[key] === 'object' && product[key].hasOwnProperty('EN') && product[key].hasOwnProperty('LU')) {
    //           // console.log("Runs Cond:")
    //           if (product[key].LU === "") {
    //             product[key].LU = product[key].EN;
    //           }
    //           else
    //           {
    //             product[key].EN = product[key].LU;
    //           }
    //         }
    //        if (Array.isArray(product[key].EN) && Array.isArray(product[key].LU)) {
    //         console.log("Condtion Runs:",product[key])
    //         if(product[key].EN.length && !product[key].LU.length)
    //         {
    //         product[key].LU[0] = product[key].EN[0]
    //         }
    //         else
    //         {
    //           product[key].EN[0] = product[key].LU[0]
    //         }
    //       }
    //       // return obj;
    //     }
    //  // }

    //     // const updatedObject = assignENtoLU(product)

    //     console.log("Updated OBject:",product)

    try {
      if (id) {
        await dispatch(editProduct(id, product)).then(() => {
          dispatch(getProducts());
        });
        toast.success(`Product edited successfully!`);
      } else {
        await dispatch(createProduct(product)).then(() => {
          dispatch(getProducts());
        });
        toast.success(`Product successfully ${status.toLowerCase()}!`);
      }
      navigate(`/products`);
    } catch {
      toast.error(`Failed to ${id ? "edit" : "create"} Product!`);
    }
  };

  const handleCreateProduct = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.DRAFT);
  };

  const handlePublishProduct = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.PUBLISHED);
  };

  const isBlocking = () => {
    return (productTitle || productCoverImage) && isPublished;
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  const handleCloseCalender = () => {
    setOpenCalender(false);
  };

  const handleCalenderOk = () => {
    // setButtonStatus(STATUS.SCHEDULED);

    setOpenCalender(false);
    handleScheduleProduct();
  };

  const handleScheduleProduct = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.SCHEDULED);
  };

  const combinedDateTime = () => {
    const selectedDate = dateTimeRef.current.getSelectedDate();
    const selectedTime = dateTimeRef.current.getSelectedTime();

    let combinedDateTime = dayjs(selectedDate);
    if (selectedDate && selectedTime) {
      // Combine date and time
      combinedDateTime = dayjs(selectedDate)
        .set("hour", dayjs(selectedTime).hour())
        .set("minute", dayjs(selectedTime).minute())
        .set("second", dayjs(selectedTime).second())
        .set("millisecond", dayjs(selectedTime).millisecond());

      // Format as ISO 8601 string
    }
    const localDateTime = combinedDateTime.format(); // Local time in default ISO format
    return localDateTime;
  };

  return (
    <>
      <Preview open={previewOpen} handleClose={handlePreviewClose} />
      <div style={{ display: "flex", justifyContent: "center", margin: "0 0" }}>
        <Button
          size="small"
          variant="contained"
          onClick={handlePreviewOpen}
          style={{ margin: 10 }}
        >
          Preview
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleCreateProduct}
          style={{ margin: 10 }}
        >
          Draft
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handlePublishProduct}
          style={{ margin: 10 }}
        >
          Publish
        </Button>
      </div>
      {/* <CalenderDialog
        ref={dateTimeRef}
        open={openCalender}
        handleClose={handleCloseCalender}
        handleOk={handleCalenderOk}
      ></CalenderDialog>

      <div style={{ display: "flex", justifyContent: "center", margin: "0 0" }}>
        <Tooltip title="Preview">
          <IconButton
            onClick={handlePreviewOpen}
            style={{ margin: 10, cursor: "pointer" }}
            size="small"
            disableRipple
            disableFocusRipple
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Draft">
          <IconButton
            onClick={handleCreateProduct}
            style={{ margin: 10, cursor: "pointer" }}
            size="small"
            disableRipple
            disableFocusRipple
          >
            <DraftsIcon />
          </IconButton>
        </Tooltip>
        {product.status === CONTENT_STATES.PUBLISHED ? (
          <Button
            size="small"
            variant="contained"
            onClick={handlePublishProduct}
            style={{ margin: 10 }}
          >
            Publish
          </Button>
        ) : (
          <SplitButton
            status={product.status}
            handleDraft={handleCreateProduct}
            handlePublishArticle={handlePublishProduct}
            setOpenCalender={setOpenCalender}
          ></SplitButton>
        )}
      </div> */}
    </>
  );
};

export default ProductButton;
